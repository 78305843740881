















































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import LinkStrategyModerateWealthDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-wealth-dialog-view-model';

@Component({
  name: 'LinkStrategyModerateWealthDialog',
  components: {
    LinkStrategyModerateLinkedGoals: () => import('./LinkStrategyModerateLinkedGoals.vue'),
    LinkStrategyModerateComparisonPesosAndModerate: () => import('./LinkStrategyModerateComparisonPesosAndModerate.vue'),
    StrategyRiskTolerance: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyRiskTolerance.vue'),
    StrategyModerateWarningChangeStrategy: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateWarningChangeStrategy.vue'),
    LinkStrategyModerateHiring: () => import('./LinkStrategyModerateHiring.vue'),
    LInkStrategyModerateTransfer: () => import('./LInkStrategyModerateTransfer.vue'),
    LinkStrategyModerateRequestSent: () => import('./LinkStrategyModerateRequestSent.vue'),
  },
})
export default class LinkStrategyModerateWealthDialog extends Vue {
  @PropSync('investorGoalId', { type: String })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String })
  synced_goal_name!: string;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  link_strategy_moderate_wealth_model = Vue.observable(
    new LinkStrategyModerateWealthDialogViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  destroyed() {
    this.link_strategy_moderate_wealth_model.resetStateValues();
  }
}
