import TYPES from '@/types';
import Vue from 'vue';

// Application
import InvestorGoalRiskToleranceDtoService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-dto-service';
import InvestorGoalRiskToleranceAnswersService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-answers-service';
import InvestorGoalRiskToleranceChoseWarningAnswerService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-chose-warning-answer-service';

// Domain
import { Answer }
  from '@/modules/flagship/risk-tolerance-questions/domain/entities/risk-tolerance-questions-entity';
import { InvestorGoalRiskToleranceDto }
  from '@/modules/flagship/investor-goal-risk-tolerance/domain/dto/investor-goal-risk-tolerance-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class LinkStrategyModerateWealthDialogViewModel {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_DTO_SERVICE)
  private readonly investor_goal_risk_tolerance_dto_service!: InvestorGoalRiskToleranceDtoService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_answer_service!:
    InvestorGoalRiskToleranceAnswersService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_CHOSE_WARNING_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_chose_warning_answer_service!:
    InvestorGoalRiskToleranceChoseWarningAnswerService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.link-sws-moderate.link-strategy-moderate-wealth-dialog';

  private readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'LinkStrategyModerateLinkedGoals',
    },
    {
      step: 1,
      component: 'LinkStrategyModerateComparisonPesosAndModerate',
    },
    {
      step: 2,
      component: 'StrategyRiskTolerance',
    },
    {
      step: 3,
      component: 'StrategyModerateWarningChangeStrategy',
    },
    {
      step: 4,
      component: 'LinkStrategyModerateHiring',
    },
    {
      step: 5,
      component: 'LInkStrategyModerateTransfer',
    },
    {
      step: 6,
      component: 'LinkStrategyModerateRequestSent',
    },
  ];

  selected_answers: Array<Answer> = [];

  investor_goal_risk_tolerance_dto: InvestorGoalRiskToleranceDto = {
    customer_id: '',
    investor_goal_id: '',
    investment_product_fund_type_id: '',
    answers: [],
  };

  current_step = 0;

  current_header_step = 1;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  width_percent = '60%';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('header_steps');

  get dynamic_width() {
    // eslint-disable-next-line no-nested-ternary
    return (this.view.$vuetify.breakpoint.xs) ? '100%' : (this.view.$vuetify.breakpoint.sm) ? '80%' : this.width_percent;
  }

  get show_stepper_header() {
    return this.current_step > 3 && this.current_step < 6;
  }

  setDynamicWidth = (width: number) => {
    this.width_percent = `${width}%`;
  }

  setInvestorGoalRiskToleranceDto = () => {
    this.investor_goal_risk_tolerance_dto_service
      .setInvestorGoalRiskToleranceDto(this.investor_goal_risk_tolerance_dto);
  }

  setInvestorGoalRiskToleranceAnswers = () => {
    this.investor_goal_risk_tolerance_answer_service
      .setInvestorGoalRiskToleranceAnswers(this.selected_answers);
  }

  setInvestorGoalRiskToleranceChoseWarningAnswer = () => {
    this.investor_goal_risk_tolerance_chose_warning_answer_service
      .setInvestorGoalRiskToleranceChoseWarningAnswer(false);
  }

  nextStep = async () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }

    if (this.current_step > 4) {
      this.current_header_step += 1;
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      this.view.$emit('closeModal');
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }

    if (this.current_step >= 4) {
      this.current_header_step -= 1;
    }
  }

  dontShowWarningChangeStrategy = (is_next_step: boolean) => {
    if (is_next_step) this.current_step += 1;
    else this.current_step -= 1;
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_header_step = 1;
    this.current_component = this.STEPS[this.current_step];
  }

  resetStateValues = () => {
    this.setInvestorGoalRiskToleranceDto();
    this.setInvestorGoalRiskToleranceAnswers();
    this.setInvestorGoalRiskToleranceChoseWarningAnswer();
  };
}
